import { createContext, PropsWithChildren, useContext, useState } from "react";


export type GlobalState = {
  showCart: boolean;
  setShowCart: (showCart: boolean) => void;
}

export const GlobalContext = createContext<GlobalState | null>(null);

const useGlobalContext = () => {
  const context = useContext(GlobalContext);

  if (!context) {
    throw new Error("Please use GlobalProvider in parent component");
  }

  return context;
};

export default useGlobalContext;

export const GlobalProvider = (props: PropsWithChildren) => {
  const [showCart, setShowCart] = useState(false);

  return (
    <GlobalContext.Provider value={{ showCart, setShowCart }}>
      {props.children}
    </GlobalContext.Provider>
  );
};
